.eclinic-aquamarine-rounded-button {
    background-color: var(--branch-light-aquamarine);
    padding: 10px 16px;
    border-radius: 8px;
    color: white;
    align-self: center;
    margin-inline: 8px;
    border-style: none;
    width: 200px;
}


.eclinic-aquamarine-rounded-button:hover {
    color: white;
    font-weight: bold;
    cursor: pointer;
}