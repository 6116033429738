.eclinic-advantages-section-container {
    width: 100%;
    background-color: white;
    padding-block: 0px;
}

.eclinic-advantages-section {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: auto;
    padding: 24px 0px;
    background-color: white;
}
.eclinic-advantages-section__img-container {  
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.eclinic-advantages-section__img {  
  /*   object-fit: cover;
    max-width: 600px;
    min-width: 300px;
    margin: auto; */
    width: 120%;
}

.eclinic-advantages-section__advages-features {
    width: 80%;
    margin: auto;
}

.eclinic-advantages-section-container_button-information {
    background-color: var(--branch-light-aquamarine);
    padding: 10px 16px;
    border-radius: 8px;
    color: white;
    align-self: center;
    margin-inline: 8px;
    border-style: none;
    margin-block-end: 48px;
}


.eclinic-advantages-section-container_button-information:hover {
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.eclinic-advantages-section_button {
    padding-block: 24px;
    padding-block-end: 36px;
}
 
@media only screen and (min-width: 768px) {

    .eclinic-advantages-section {
        display: flex;
        flex-direction: row;
        max-width: 1200px;
    }

    .eclinic-advantages-section__img-container {  
        width:50%;
    }

    .eclinic-advantages-section__img {  
      /*   object-fit: cover; */
      /*   width: 100%;
        max-width: 500px; */
        width: 120%;
    }

    .eclinic-advantages-section__advages-features {
        width: 50%;
    }

}