@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500&display=swap);
body {
  background-color: white;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #0c3a5b;
  --branch-gray: #f4f5f1;
  --branch-opaque-aquamarine: #19c1ce;
  --branch-light-aquamarine: #1ddbd6;
  --branch-smooth-blue: #d9fbff;
  --branch-light-blue: #008eaf;
  --gray-e: #707070;
  --h1-default-weight: 500;
  --branch-line-height: 1.4rem;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.App {
  line-height: 1.25rem;
  background-color: #f4f5f1;
  background-color: var(--branch-gray);
  text-align: center;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header-container {
  text-align: center;
  background-color:white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.header-logo-container {
  padding: 24px 0;
  width: 66%;
  margin: auto;
}

.header-logo-container__img {
    min-width: 120px;
    max-width: 150px;
}

.header__menu {
  display: -webkit-flex;
  display: flex;
  width: 33%;
  padding-inline: 32px;
}

/* 
.header__menu svg {
  align-self: center;
  justify-self: end;
} */

.header__menu-navbar {
  opacity: 0;
  position: fixed;
  padding: 10px 10px;
  left: 100%;
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
  background-color: white;
  list-style: none;
  text-align: end;
  z-index: 5;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, .8);
  border-radius: 7px;
  transition: opacity .1s ease;
}

/* .header__menu-navbar a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 0.9rem;
  line-height: 1.5rem;
} */

.header__menu-navbar a:hover {
  color: var(--branch-light-aquamarine);
}


.p-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .black-background_overlay {
  z-index: 3;
  opacity: 0;
  transition: opacity .5s ease;
  background-color: rgba(0, 0, 0, .5);
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
} */



@media only screen and (min-width: 768px) {

  .header-container {
    text-align: center;
    background-color:white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .header__menu-navbar {
    opacity: 1;
    display: inline-block;
    position: absolute;
    list-style-type: none;
    padding: 10px 10px;
    left: 100%;
    background-color: var(--ba);
    text-align: end;
    box-shadow: none;
    border-radius: 7px;
    transition: none;
    font-weight: bold;
  }

  .header-logo-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    width: 30%;
    -webkit-padding-end: 0%;
            padding-inline-end: 0%;
    -webkit-padding-start: 2.5%;
            padding-inline-start: 2.5%;
  }

  .header__menu {
    width: 70%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-padding-end: 2.5%;
            padding-inline-end: 2.5%;
    -webkit-padding-start: 0%;
            padding-inline-start: 0%;
  }

 /*  .black-background_overlay {
    display: block;
    opacity: 1.0;
  } */

}


@media only screen and (min-width: 1024px) {

  .header-logo-container {
    display: -webkit-flex;
    display: flex;
    width: 25%;
    -webkit-padding-end: 0%;
            padding-inline-end: 0%;
    -webkit-padding-start: 10%;
            padding-inline-start: 10%;
  }

  .header__menu {
    display: -webkit-flex;
    display: flex;
    width: 75%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-padding-end: 10%;
            padding-inline-end: 10%;
    -webkit-padding-start: 0%;
            padding-inline-start: 0%;
  }
  
}


.landing-page-head-container {
    display: grid;
    height: auto;
    background-color: white;
}

.landing-page-head-container_img {
    width: 90%;
    height: auto;
}

.landing-page-head-container_title {
    padding: 0;
}

.landing-page-head-container h1 {
    font-weight: bold;
    font-size: 1.65rem;
    color: var(--primary-color);
    text-align: center;
    margin-block: 0;
    margin-inline: 8px;
}

.landing-page-head-container p {
    width: 90%;
    margin: 30px auto;
    font-weight: 400;
    font-size: 0.9rem;
    color: var(--primary-color);
    text-align: center;
}

.landing-page-head-container span {
    font-weight: bold;
    font-size: 1.65rem;
    color: var(--branch-light-aquamarine);
}

#landing-page-header_price_from{
    font-weight: 700;
    font-size: 1rem;
    color: var(--gray-e);
}

#landing-page-header_price_text {
    font-weight: 900;
    font-size: 1rem;
    color: var(--branch-light-aquamarine);
}

.landing-page-header_price {
    font-size: 1.0rem;
    color: var(--primary-color);
    text-align: center;
    color: var(--branch-light-aquamarine);
}

.landing-page-head-container_button {
    color: white;
    font-weight: var(--h1-default-weight);
    padding: 10px 15px;
    text-decoration: none;
    background-color: var(--branch-opaque-aquamarine);
    border-radius: 5px;
}

.demoeclinic-container {
    margin: 20px;
}

.text-writter-c {
    -webkit-align-self: center;
            align-self: center;
}

.landing-page-head-container_title-text-container {
    line-height: 2.0rem;
}

@media only screen and (min-width: 768px){

    .landing-page-head-container h1 {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        font-weight: bold;
        font-size: 1.8rem;
    }

    .landing-page-head-container_title {
        padding: 36px 0;
    }

    .landing-page-head-container span {
        font-weight: bold;
        font-size: 1.8rem;
    }

    .landing-page-head-container p {
        width: 75%;
        margin: 36px auto;
        font-weight: 400;
        font-size: 1rem;
    }

    #landing-page-header_price_text {
        font-size: 1.5rem;
    }

    #landing-page-header_price_from{
        font-size: 1.5rem;
    
    }

    .landing-page-head-container_button {
        color: white;
        padding: 20px 50px;
        font-weight: bolder;
        font-size: 1.2rem;
        text-decoration: none;
        background-color: #1ddbd6;
        border-radius: 10px;
    }
    
.demoeclinic-container {
    margin: 68px auto;
   }

   
   .landing-page-head-container_title-text-container {
        display: -webkit-flex;
        display: flex;
        width: 100%;
        -webkit-justify-content: center;
                justify-content: center;
        font-weight: bold;
    }
 }

 @media only screen and (min-width: 1024px) {

    
.landing-page-head-container_title {
    padding: 0px 0;
}

    .landing-page-head-container p {
        width: 75%;
        margin: 16px auto;
        font-weight: 500;
        font-size: .8rem;
    }

    #landing-page-header_price_text {
        font-size: 1.5rem;
    }

    #landing-page-header_price_from{
        font-size: 1.5rem;
    }

    .demoeclinic-container {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            margin: 8px auto;
            width: 380px;
            height: auto;
       }

       .landing-page-head-container_button {
            color: white;
            font-weight: var(--h1-default-weight);
            padding: 12px 36px;
    }

    .landing-page-header_price_container {
        padding-block: 1px;
    }

 }
.eclinic-description-section {
    background-color: white;
    width: 100%;
}

.eclinic-description-container {
    padding: 50px 0;
}

.eclinic-description-2-subsection {
    width: 100%;
    background-color: var(--branch-gray);
    padding-block: 36px;
}

.eclinic-description-container__title, .eclinic-description-2-container__title, .eclinic-description-3-container__title {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--primary-color);
    width: 100%;
    margin-block: 16px;
    font-weight: bold;
    text-align: center;
}

.eclinic-description-3-container__description {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    margin: auto;
    height: auto;
    -webkit-order: 2;
            order: 2;
}

.eclinic-description-container__title span{
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--branch-light-aquamarine);
}

.eclinic-description-container__text, .eclinic-description-2-container__text {
    color: var(--primary-color);
    width: 90%;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: justify;
    margin: 8px auto;
}

.eclinic_description__image {
    height: auto;
    object-fit: cover;
    width: 100%;
}

.eclinic-description-2-container__img {
    max-width: 280px;
    width: 100%;
    height: auto;
    margin: auto;
}

.featurechecks, .eclinic-description-3_features {
   padding: 16px;
   -webkit-padding-start: 32px;
           padding-inline-start: 32px;
}

.eclinic-description-3-container__container {
    display: -webkit-flex;
    display: flex;
    max-width: 280px;
    width: 100%;
    height: auto;
    -webkit-order: 1;
            order: 1;
    margin: auto;
}

.eclinic-description-3 {
    width: 100%;
    margin: auto;
    background-color: rgba(217, 251, 255, .45);
}

.eclinic-description-3-container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    max-width: 1080px;
    margin: auto;
    height: auto;
    padding: 50px 0;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.eclinic-description-4 {
    width: 100%;
    background-color: white;
}

.eclinic-description-4-container { 
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 1080px;
    width: 100%;
    margin: auto;
    height: auto;
    padding: 50px 0;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
 
}

.eclinic-description-3__marketplace-video {
    height: 80vh;
    margin: auto;
}

.eclinic-description-3-container__text {
    color: var(--primary-color);
    text-align: justify;
    font-size: 1rem;
    width: 90%;
    line-height: 1.5rem;
    margin: 24px auto;
}

.featureimage {
           -webkit-order: 2;
                   order: 2;
           display: -webkit-flex;
           display: flex;
           justify-items: center;
           -webkit-align-items: center;
                   align-items: center;
           width: 100%;
 }

 .eclinic-description-1-container {
     width: 100%;
     -webkit-padding-start: 0px;
             padding-inline-start: 0px;
 }


@media only screen and (min-width: 768px) {

    .eclinic-description-container {
        display: -webkit-flex;
        display: flex;
        width: 90%;
        max-width: 1080px;
        margin: auto;
 /*        grid-template-columns: 25% 25% 25% 25%;
        grid-template-areas: 'feature feature featureimage featureimage'
                             'feature feature featureimage featureimage'
                             'featurechecks featurechecks featureimage featureimage'; */
        height: auto;
        padding: 50px 0;
    }

    .eclinic-description-1-container {
        width: 100%;
    }

    .eclinic-description-2-container {
        display: -webkit-flex;
        display: flex;
        width: 90%;
        max-width: 1080px;
        margin: auto;
        height: auto;
        padding: 50px 0;
        background-color: var(--branch-gray);
    }

    .eclinic-description-4-container { 
        -webkit-flex-direction: row; 
                flex-direction: row;
        width: 90%;
    }

    .eclinic-description-2-container__container {
        display: -webkit-flex;
        display: flex;
        max-width: 500px;
        width: 50%;
        height: auto;
    }

    .eclinic-description-3-container {
        width: 90%;
    }

    .eclinic-description-3-container__container {
        display: -webkit-flex;
        display: flex;
        max-width: 500px;
        width: 50%;
        height: auto;
        -webkit-order: 3;
                order: 3;
    }

    .eclinic-description-2-container__img {
        max-width: 500px;
        min-width: 280px;
        width: 100%;
        height: auto;
    }

    .eclinic-description-3_features {
        -webkit-margin-start: 0%;
                margin-inline-start: 0%;
    }

    .eclinic-description-2-container__description {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 50%;
        -webkit-padding-start: 16px;
                padding-inline-start: 16px;
        margin: auto;
        height: auto;
    }

    .eclinic-description-3-container__description {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 50%;
        -webkit-padding-start: 16px;
                padding-inline-start: 16px;
        margin: auto;
        height: auto;
        -webkit-order: 1;
                order: 1;
    }

    .eclinic-description-2-container__text {
        text-align: start;
        font-size: 1.25rem;
        line-height: 2rem;
        margin-inline: 0px;
        margin-block: 16px;
        margin: 24px 0;
    }

    .eclinic-description-3-container__title, .eclinic-description-2-container__title, .eclinic-description-container__title { 
        text-align: start;
        font-size: 2rem;
        line-height: 3rem;
        margin-block: 8px;
    }

    .eclinic-description-3-container__text {
        text-align: start;
        font-size: 1.25rem;
        line-height: 2rem;
        margin-block: 8px;
        margin-inline: 0px;
    }


    .eclinic-description-container__text {
        color: var(--primary-color);
        width: 100%;
        font-size: 1.25rem;
        text-align: start;
        margin: 8px 0px;
        line-height: 2rem;
    }

    .featureimage {
 /*        grid-area: featureimage; */
        -webkit-order: 2;
                order: 2;
        display: -webkit-flex;
        display: flex;
        justify-items: center;
        -webkit-align-items: center;
                align-items: center;
        width: 50%;
    }

    .feature {
   /*      grid-area: feature; */
        -webkit-padding-start: 16px;
                padding-inline-start: 16px;
        -webkit-order: 1;
                order: 1;
        width: 50%;
    }

    .featurechecks {
        -webkit-margin-start: 0%;
                margin-inline-start: 0%;
     /*    grid-area: featurechecks; */
    }
}
.feature-container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.feature-container__check{
    width: 48px;
    height: 48px;
    /* margin: 8px 16px; */
}

.feature-container__check-img {
    width: 17.5px;
    height: 17.5px;
    vertical-align: middle;
    margin-block: 2px;

}

.feature-container p {
    text-align: start;
    color: var(--primary-color);
    font-size: 0.75rem;
    font-weight: bold;
    margin: 1px;
    line-height: 1.5rem;
}

.feature-container__check-img-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-margin-end: 8px;
            margin-inline-end: 8px;
    line-height: 1.5rem;
}
.eclinic-aquamarine-rounded-button {
    background-color: var(--branch-light-aquamarine);
    padding: 10px 16px;
    border-radius: 8px;
    color: white;
    -webkit-align-self: center;
            align-self: center;
    margin-inline: 8px;
    border-style: none;
    width: 200px;
}


.eclinic-aquamarine-rounded-button:hover {
    color: white;
    font-weight: bold;
    cursor: pointer;
}
.user-preference-us-section {
    background-color: var(--branch-light-aquamarine);
    width: 100%;
    margin: auto;
    padding: 20px 0;
}

.user-preference-us-container {
   
    width: 100%;
    margin: auto;
    max-width: 1080px;
    
}

.user-preference-us__title {
    color: white;
    font-size: 1.75rem;
    line-height: 2.4rem;
    font-weight: bold;
    margin: 24px auto;
    -webkit-margin-before: 16px;
            margin-block-start: 16px;
    -webkit-margin-after: 36px;
            margin-block-end: 36px;
    width: 90%;
}

.user-preference_feature {
    min-width: 260px;
    margin-inline: auto;
    max-width: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-content:stretch;
            align-content:stretch;
}

.user-preference_feature-container{
    display: -webkit-flex;
    display: flex;
    justify-items: start;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 8px;
}

.user-preference_feature h3 {
    color: white;
    font-size: .9rem;
    margin: 8px auto;
    text-align: center;
}

.user-preference_feature p {
    color: white;
    font-size: .75rem;
    font-weight: 500;
    margin: 8px auto;
    width: 75%;
    text-align: center;
}

.user-preference_feature-icon {
    margin-inline: auto;
    -webkit-margin-before: 0px;
            margin-block-start: 0px;
    -webkit-margin-after: 12px;
            margin-block-end: 12px;
}

.user-preference_feature-icon-img {
    object-fit: cover;
    max-width: 70px;
    min-width: 60px;
    width: 100%;
}

@media only screen and (min-width: 768px){
    .user-preference_feature-container{
        -webkit-flex-direction: row;
                flex-direction: row;
    }
}


.customer-review {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 220px;
    max-width: 320px;
    -webkit-align-self: center;
            align-self: center;
    margin: 0px auto;
    padding-top: 16px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: white;
}

.customer-review__item-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: space-between;
            align-content: space-between;
    min-width: 200px;
    max-width: 350px;
    margin-block: 16px;
}

.customer-review__footer {
    width: 100%;
    height: 75px;
    padding: 10px;
    background-color: var(--branch-smooth-blue);
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;
}

.customer-review__footer p {
    margin: auto;
}

.customer-review__text {
    font-style: oblique;
    width: 90%;
    height: 110px;
    margin: 8px auto;
    font-size: 0.75rem;
    text-align: center;
}

.customer-review__footer-name {
    color: var(--primary-color);
    font-weight: 500;
    font-size: .9rem;
    margin: auto;
    padding: 0;
    text-align: center;
}

.customer-review__footer-specialty {
    padding: 0;
    margin: 0;
    font-size: .8rem;
    color: var(--branch-light-aquamarine);
}

.customer-review__footer-medical-entity {
    padding: 0;
    margin: 0;
    font-size: .8rem;
    color: var(--branch-light-aquamarine);
}



/* telmedia 
finaznas agendaminetos de citas
historia clinica */
.customer-reviews-container {
    display: block;
    background-color: var(--branch-light-aquamarine);
    padding: 20px 0px;
}

.customer-reviews-container__title {
    color: var(--primary-color);
    font-size: 1.75rem;
    margin: auto;
}

.customer-review__review {
    display: -webkit-flex;
    display: flex;
    height: auto;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 20x 16px;
    justify-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}


@media screen and (min-width: 768px) {
    .customer-review__review {
        -webkit-flex-direction: row;
                flex-direction: row;
        justify-items: center;
    }

    .customer-review__review {
    }
}
.advantages-feature-container {
    display: grid;
    width: 100%;
    grid-template-columns: 10% 90%;
    grid-template-areas: "icon title"
                         ". description";                    
}
.advantages-feature__icon-container {
   display: -webkit-flex;
   display: flex; 
   -webkit-justify-content: center; 
           justify-content: center;
   grid-area: icon;
}

.advantages-feature__icon {
    width: 20px;
    height: 20px;
    -webkit-align-self: center;
            align-self: center;
}

.advantages-feature__title {
    color: var(--primary-color);
    font-size: .75rem;
    font-weight: bold;
    grid-area: title;
    margin: 8px;
    text-align: start;
}
.advantages-feature__text {
    font-size: .75rem;
    grid-area: description;
    margin-block: 0px;
    margin-inline: 8px;
    text-align: start;
    width: 100%;
    line-height: var(--branch-line-height);
}

@media only screen and (min-width: 768px) {


    .advantages-feature-container {
        width: 90%;                  
    }
    .advantages-feature__text {
        font-size: .75rem;
        margin-block: 0px;
        margin-inline: 8px;
        text-align: start;
        line-height: var(--branch-line-height);
    }
}
.eclinic-advantages-section-container {
    width: 100%;
    background-color: white;
    padding-block: 0px;
}

.eclinic-advantages-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: auto;
    margin: auto;
    padding: 24px 0px;
    background-color: white;
}
.eclinic-advantages-section__img-container {  
    width: 80%;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
}

.eclinic-advantages-section__img {  
  /*   object-fit: cover;
    max-width: 600px;
    min-width: 300px;
    margin: auto; */
    width: 120%;
}

.eclinic-advantages-section__advages-features {
    width: 80%;
    margin: auto;
}

.eclinic-advantages-section-container_button-information {
    background-color: var(--branch-light-aquamarine);
    padding: 10px 16px;
    border-radius: 8px;
    color: white;
    -webkit-align-self: center;
            align-self: center;
    margin-inline: 8px;
    border-style: none;
    -webkit-margin-after: 48px;
            margin-block-end: 48px;
}


.eclinic-advantages-section-container_button-information:hover {
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.eclinic-advantages-section_button {
    padding-block: 24px;
    -webkit-padding-after: 36px;
            padding-block-end: 36px;
}
 
@media only screen and (min-width: 768px) {

    .eclinic-advantages-section {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        max-width: 1200px;
    }

    .eclinic-advantages-section__img-container {  
        width:50%;
    }

    .eclinic-advantages-section__img {  
      /*   object-fit: cover; */
      /*   width: 100%;
        max-width: 500px; */
        width: 120%;
    }

    .eclinic-advantages-section__advages-features {
        width: 50%;
    }

}


.product-service-feature__container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    -webkit-margin-before: 16px;
            margin-block-start: 16px;
}

.product-service-feature__icon {
    width: 17.5px;
    height: 17.5;
    -webkit-align-self: flex-start;
            align-self: flex-start;
}

.product-service-feature__entry {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-block: 4px;
}

.product-service-feature__text {
    font-size: .75rem;
    color: var(--primary-color);
    -webkit-margin-start: 8px;
            margin-inline-start: 8px;
    text-align: start;
}
.product-service__item-container {
    margin-inline: 4px;
    margin-block: 16px;
    display: -webkit-flex;
    display: flex;
    padding: 30px;          
    padding-block: 24px;          /*added*/
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 280px;
    min-width: 260px;
    background-color: white;
    opacity: 1;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0px 0.5px 10px rgb( 0, 0, 0, .10);  
    transition: all 250ms ease-in-out;
}


.product-service__item-container:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    box-shadow: 0px 0.5px 20px rgb( 0, 0, 0, .15);  
}

/* .product-service__item-container::after {
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .product-service__item-container:hover::after {
    opacity: 1;
  } */

  .solid-divider {
    background-color:  #e8e8e8;
    width: 100%;
    height: 1.5px;
    -webkit-margin-after: 32px;
            margin-block-end: 32px;
}

.product-service__item{
    width: 250px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 280px;
    min-width:  100px;
    height: auto;
    margin: 5px auto;
    padding: 16px;
}

.product-service__round-container {
    width: 80px;
    margin-inline: auto;
    -webkit-padding-before: 5px;
            padding-block-start: 5px;  /*10px*/
    -webkit-padding-after: 20px;
            padding-block-end: 20px;   /*30px*/
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.service-container__item-title {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-color);
    text-align: center;
    margin: auto;
    -webkit-margin-before: 0px;
            margin-block-start: 0px;
    -webkit-margin-after: 10px;
            margin-block-end: 10px;
}

.service-container__item-description {
    width: 100%;
    font-size: .75rem;
    margin: auto;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    text-align: justify;
    font-weight: 500;
    color: var(--primary-color);
}
.product-service-list-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-content: center;
            align-content: center;
    margin: auto;
    padding: 48px 16px;
    background-color: var(--branch-gray);
    opacity: 1;
    width: 100%;
}

.product-service-list__title-container {
    width: 100%;
}

.product-service-list__title-container-title {
    width: 100%;
    color: var(--primary-color);
    margin: auto;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
}

.product-service-list__title-container-description {
    width: 90%;
    margin: 30px auto;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.7rem;
    color: var(--primary-color);
    text-align: justify;
}

.product-service-list__title-container-button {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 0px 48px;
    margin: 50px auto;
}

.product-service-list__title-container-button-content {
    color: white;
    font-weight: var(--h1-default-weight);
    padding: 10px 15px;
    margin: auto;
    text-decoration: none;
    background-color: var(--branch-opaque-aquamarine);
    border-radius: 5px;
}

.product-service-list__title-container-content {
    object-fit: cover;
    -webkit-align-self: center;
            align-self: center;
    width: 100%;
}

.product-service-list_schedule-demo {
    background-color: var(--branch-light-aquamarine);
    padding: 10px 16px;
    border-radius: 8px;
    color: white;
    -webkit-align-self: center;
            align-self: center;
    margin-inline: 8px;
    border-style: none;
    -webkit-margin-before: 16px;
            margin-block-start: 16px;
    -webkit-margin-after: 16px;
            margin-block-end: 16px;
}


.product-service-list_schedule-demo:hover {
    color: white;
    font-weight: bold;
    cursor: pointer;
}

#marketplace-premium_button-container {
    margin-block: 28px;
}


@media only screen and (min-width: 768px) {

    .product-service-list__title-container-title {
        width: 80%;
        color: var(--primary-color);
        margin: auto;
        font-weight: bold;
        font-size: 1.75rem;
        line-height: 2.5rem;
    }

    .product-service-list-section {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        max-width: 1080px;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-align-content: center;
                align-content: center;
        margin: auto;
        padding: 48px 24px;
        background-color: var(--branch-gray);
        opacity: 1;
        width: 90%;
    }

    .product-service-list__title-container-description {
        width: 80%;
        margin: 30px auto;
        font-weight: 400;
        font-size: .8rem;
        color: var(--primary-color);
        text-align: center;
    }

    .product-service-list__title-container-button {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start;
        padding: 0px 55px;
        margin: 50px auto;
    }

    .product-service-list__title-container {
        -webkit-margin-after: 48px;
                margin-block-end: 48px;
    }
    
}
.web-page-own-domain-advise-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: white;
    margin-inline: auto;
    margin-block: 16px;
    padding-inline: 16px;
    padding-block: 12px;
    box-shadow: 0px 0.5px 10px rgb( 0, 0, 0, .33);  
    border-radius: 8px;
}

.web-page-own-domain-advise-container__advertisement {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.web-page-own-domain-advise-container__advertisement-icon-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
    margin-inline: 8px;
    margin-block: 16px;
}

.web-page-own-domain-advise-container__advertisement-icon {
   min-width: 60px;
   height: auto;
   max-width: 80px;

}

.web-page-own-domain-advise-container__title {
    color: var(--primary-color);
    font-size: 1.5rem;
    text-align: start;
    line-height: 1.8rem;
    margin-block: 8px;
}

.web-page-own-domain-advise-container__text {
    margin-block: 4px;
    font-size: .85rem;
    color: #808080;
    text-align: start;
}

.web-page-own-domain-advise-container__info{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 8px;
}

.web-page-own-domain-to_expert-button {
    background-color: var(--branch-light-aquamarine);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 8px;
    font-size: 1rem;
    color: white;
    padding: 12px 32px;
    border: 1px solid var(--branch-light-aquamarine);
    -webkit-align-self: center;
            align-self: center;
    justify-self: center;
    cursor: pointer;
}

.web-page-own-domain-to_expert-button-conatainer {
    display: -webkit-flex;
    display: flex;
    padding-inline: 32px;
    padding-block: 16px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

@media only screen and (min-width: 768px){
    .web-page-own-domain-advise-container {
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-block: 32px;
    }

    .web-page-own-domain-advise-container__title {
        font-size: 1.25rem;
        text-align: start;
        line-height: 1.5rem;
        margin-block: 8px;
    }
    
    .web-page-own-domain-advise-container__text {
        margin-block: 4px;
        font-size: .9rem;
        color: #808080;
        text-align: start;
    }

    .web-page-own-domain-advise-container__advertisement-icon-container {
        display: -webkit-flex;
        display: flex;
        -webkit-align-self: center;
                align-self: center;
        margin-inline: 16px;
        margin-block: 16px;
    }
    
}


.whatsapp-contact-icon_container {   
    width: 80px;
    height: 80px;
    position: fixed;
    z-index: 10;
    bottom: 24px;
    right: 24px;
    border-radius: 20px;
}

.whatsapp-contact-icon__img {   
    object-fit: cover;
    width: 70px;
    height: auto;
}

.whatsapp-contact-icon__img:hover {   
    cursor: pointer;
}
.hubspot-form-drhelp {
    width: 100%;
    margin:auto;
    background-color: white;
    padding: 32px 16px;
}

.hubspot-form-container {
    width: 70%;
    margin: auto;
}
.footer-container {
    text-align: center;
    background-color: var(--primary-color);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    padding: 16px;
    width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .footer-logo-container{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-margin-after: 20px;
            margin-block-end: 20px;
  }

  .footer-logo-container p {
    font-size: .75rem;
    margin-inline: auto;
    -webkit-margin-before: 0px;
            margin-block-start: 0px;
    -webkit-margin-after: 8px;
            margin-block-end: 8px;
  }
 

.eclinic-footer {
  background-color: var(--primary-color);
  padding: 16px 0;
  width: 100%;
}

  .footer-logo__image-wrapper {
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    margin-block: 0;
  }

  .footer-logo__image-wrapper a {
    margin: auto;
  }

  .footer__logo {
  
    width: 100%;
    min-width: 150px;
    max-width: 220px;
    height: auto;
    justify-self: center;
}

.footer_nav-companies-entries, .footer_nav-modules-entries {
  color: white;
  list-style: none;
  text-align: start;
}

.footer_nav-companies-entries li, .footer_nav-modules-entries li {
  
  font-size: .9rem;
  margin-block: 2px;
}

.footer_entry {
  color: white;
  text-decoration: none;
}

.footer_entry:visited {
  text-decoration: none;
  color: white;
}

.eclinic-footer p {
 color: white;
/*  font-size: .9rem; */
 text-align: center;
}

.footer-logo__icons-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-block: 4px;
}

.footer-logo__icons-circular {
  background-color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  margin-inline: 8px;
}

.footer-logo__icons-wrapper a {
  margin-inline: 8px;
  -webkit-align-self: center;
          align-self: center;
  justify-self: center;
  display: -webkit-flex;
  display: flex;
}

.footer-logo__icons-wrapper a svg {
  -webkit-align-self: center;
          align-self: center;
  justify-self: center;
}

.footer_nav__companies-container, .footer_nav__modudes-container {
  width: 100%;
}

.footer_nav__companies-container h3, .footer_nav__modules-container h3 {
  width: 100%;
  text-align: start;
  color: white;
  font-size: 1rem;
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
}

.copyRigth {
  color: white;
  font-size: .75rem;
  margin: 16px 8px;

}

@media only screen and (min-width: 768px){
  .footer-container {
    padding: 32px 30px;
    -webkit-flex-direction: row;
            flex-direction: row;
  }


  .footer-logo-container, .footer_nav__companies-container, .footer_nav__modules-container {
    width:33%;
  }

.eclinic-footer {
  background-color: var(--primary-color);
  padding: 16px 0;
}

  .footer-logo__image-wrapper {
    display: -webkit-flex;
    display: flex;
    justify-items: start;
  }

  .footer__logo {
    min-width: 150px;
    max-width: 250px;
    height: auto;
}

.footer_nav-entries {
  color: white;
  list-style: none;
  text-align: start;
}

.footer_nav-entries li{
  
  font-size: 1.0rem;
}

.footer_entry {
  color: white;
  text-decoration: none;
}

.footer_entry:visited {
  text-decoration: none;
  color: white;
}

.eclinic-footer p {
    color: white;
    font-size: .9rem;
    text-align: center;
    margin: auto;
  }

.footer-divider {
  background-color: white;
  height: 1px;
  margin: 24px 5%;
}

.copyRigth {
  margin: 20px 8px;
}


}
.talk-to-expert-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-block: 24px;
}
.meetings-iframe-container {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.talk-to-expert__back_button {
    color: white;
    background-color: var(--branch-light-aquamarine);
    border-radius: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 50px;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    padding-inline: 48px;
    -webkit-margin-after: 36px;
            margin-block-end: 36px;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    cursor: pointer;
}

.talk-to-expert__back_button p {
    -webkit-align-self: center;
            align-self: center;
}

.talk-to-expert__back_button-link {
    text-decoration: none;
}
/* 

G-HV86MG1D9D
G-HV86MG1D9D
G-HV86MG1D9D */
.frequent-question-section {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 16px;
}

.frequent-question-section__title {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin: 24px auto;
}

.frequent-question-section__questions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}


@media only screen and (min-width: 768px) {

    .frequent-question-section {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 36px;
    }

    .frequent-question-section__title {
        color: var(--primary-color);
        font-size: 1.75rem;
        margin: 36px auto;
    }
}


.question-container {
    background-color: white;
    box-shadow: 0 2.5px 0.5px 1px rgba(125, 125, 125, 0.07);
    margin-block: 8px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-inline: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 8px;
}

.question__question {
    font-size: .8rem;
    margin-inline: 8px;
    cursor: pointer;
    text-align: start;
}

.question-container_visible-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.question__icon-container {
    width: 50px;
    height: 50px;
}

.question-container_answer-container {
    width: 90%;
    overflow: hidden;
    margin: auto;
}

.question-container_answer-container p {
    font-size: 0.75rem;
    text-align: start;
    padding-block: 16px;

}

.question_anim {
    overflow: hidden;
}

.question_anim-enter{
    height: 0px;
 }

 .question_anim-enter-active{
     height: 125px;
    transition: all 500ms ease;
}

.question_anim-exit-active {
    height: 0px;
    transition: all 500ms ease;
}

.question_anim-enter-active-done{
    
}


@media only screen and (min-width: 768px) {
    .question-container {
        box-shadow: 0 2.5px 0.5px 1px rgba(125, 125, 125, 0.07);
        margin-block: 8px;
        width: 75%;
        margin-inline: auto;
        padding: 16px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .question__question {
        font-size: 1.0rem;
        margin-inline: 16px;
    }

    .question-container_answer-container p {
        font-size: 0.9rem;
        text-align: start;
        padding-block: 16px;
    
    }

}
.epayco-response-section {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 80vh;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.epayco-response__store-icon-container {
    -webkit-align-self: center;
            align-self: center;
    justify-self: center;
}

.epayco-response__store-icon {
    -webkit-align-self: center;
            align-self: center;
    justify-self: center;
}

.epayco-response__thanks-text {
    font-size: 1.5rem;
    color: var(--primary-color);
    -webkit-align-self: center;
            align-self: center;
}

.epayco-response__back-button {
    background-color: var(--branch-light-aquamarine);
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 45px;
    padding-inline: 48px;
    margin-inline: auto;
    -webkit-margin-before: 16px;
            margin-block-start: 16px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
}

.epayco-response__back-button p { 
    -webkit-align-self: center; 
            align-self: center;
}

.epayco-response__back-button-link {
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-inline: auto;
}
