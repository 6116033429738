.landing-page-head-container {
    display: grid;
    height: auto;
    background-color: white;
}

.landing-page-head-container_img {
    width: 90%;
    height: auto;
}

.landing-page-head-container_title {
    padding: 0;
}

.landing-page-head-container h1 {
    font-weight: bold;
    font-size: 1.65rem;
    color: var(--primary-color);
    text-align: center;
    margin-block: 0;
    margin-inline: 8px;
}

.landing-page-head-container p {
    width: 90%;
    margin: 30px auto;
    font-weight: 400;
    font-size: 0.9rem;
    color: var(--primary-color);
    text-align: center;
}

.landing-page-head-container span {
    font-weight: bold;
    font-size: 1.65rem;
    color: var(--branch-light-aquamarine);
}

#landing-page-header_price_from{
    font-weight: 700;
    font-size: 1rem;
    color: var(--gray-e);
}

#landing-page-header_price_text {
    font-weight: 900;
    font-size: 1rem;
    color: var(--branch-light-aquamarine);
}

.landing-page-header_price {
    font-size: 1.0rem;
    color: var(--primary-color);
    text-align: center;
    color: var(--branch-light-aquamarine);
}

.landing-page-head-container_button {
    color: white;
    font-weight: var(--h1-default-weight);
    padding: 10px 15px;
    text-decoration: none;
    background-color: var(--branch-opaque-aquamarine);
    border-radius: 5px;
}

.demoeclinic-container {
    margin: 20px;
}

.text-writter-c {
    align-self: center;
}

.landing-page-head-container_title-text-container {
    line-height: 2.0rem;
}

@media only screen and (min-width: 768px){

    .landing-page-head-container h1 {
        width: fit-content;
        font-weight: bold;
        font-size: 1.8rem;
    }

    .landing-page-head-container_title {
        padding: 36px 0;
    }

    .landing-page-head-container span {
        font-weight: bold;
        font-size: 1.8rem;
    }

    .landing-page-head-container p {
        width: 75%;
        margin: 36px auto;
        font-weight: 400;
        font-size: 1rem;
    }

    #landing-page-header_price_text {
        font-size: 1.5rem;
    }

    #landing-page-header_price_from{
        font-size: 1.5rem;
    
    }

    .landing-page-head-container_button {
        color: white;
        padding: 20px 50px;
        font-weight: bolder;
        font-size: 1.2rem;
        text-decoration: none;
        background-color: #1ddbd6;
        border-radius: 10px;
    }
    
.demoeclinic-container {
    margin: 68px auto;
   }

   
   .landing-page-head-container_title-text-container {
        display: flex;
        width: 100%;
        justify-content: center;
        font-weight: bold;
    }
 }

 @media only screen and (min-width: 1024px) {

    
.landing-page-head-container_title {
    padding: 0px 0;
}

    .landing-page-head-container p {
        width: 75%;
        margin: 16px auto;
        font-weight: 500;
        font-size: .8rem;
    }

    #landing-page-header_price_text {
        font-size: 1.5rem;
    }

    #landing-page-header_price_from{
        font-size: 1.5rem;
    }

    .demoeclinic-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 8px auto;
            width: 380px;
            height: auto;
       }

       .landing-page-head-container_button {
            color: white;
            font-weight: var(--h1-default-weight);
            padding: 12px 36px;
    }

    .landing-page-header_price_container {
        padding-block: 1px;
    }

 }