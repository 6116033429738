.whatsapp-contact-icon_container {   
    width: 80px;
    height: 80px;
    position: fixed;
    z-index: 10;
    bottom: 24px;
    right: 24px;
    border-radius: 20px;
}

.whatsapp-contact-icon__img {   
    object-fit: cover;
    width: 70px;
    height: auto;
}

.whatsapp-contact-icon__img:hover {   
    cursor: pointer;
}